import React, { Component } from "react";
import { HajjReadings } from "master-react-lib";
import Mosque from "../assets/imgs/mosque.png";
import SectionTitle from "./Common/SectionTitle";
import CardTwo from "./Common/CardTwo/CardTwo";

const cardTwoData = [
  {
    name: "Hassan Akber",
    date: "MAY 05, 2020",
    img: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-blogs/Zamzam-StepByStepGuideForUmrah-blogcover-card-image.png",
    title: "Step-by-Step Guide to Preparing for Umrah",
    Discription:
      "Umrah is a special pilgrimage to the holy city of Mecca, which Muslims can perform at any time of the year. It holds immense spiritual ...",
    link: "reads/guide-for-umrah",
  },
];

class HajjReading extends Component {
  render() {
    return (
      <div className="SectionContainer">
        {/* <HajjReadings title="Hajj Reading" carouselItems={carouselItems} /> */}
        <SectionTitle
          TitleLight="LATEST"
          TitleBold="READS/VIEWS"
          buttonLebel="ALL NEWS"
          Link="/reads-views"
          //target="new"
          ButtonStatus={true}
        />
        <CardTwo
          cardTwoData={cardTwoData}
          col="4"
          Imgcol="12"
          Contentcol="12"
          spacing={2}
          buttonLabel="READ MORE"
          hoverbackground="#28b356"
          titleColor="#28b356"
          nameDateStatus={false}
        />
      </div>
    );
  }
}

export default HajjReading;
