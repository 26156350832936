import React, { Component } from "react";
import { FooterWeb } from "master-react-lib";
import DCLogo from "../../assets/imgs/dc_logo.png";
import UmrahServices from "../UmrahServices";

const footerLinks = [
  {
    headingTitle: "Helpful resources",
    columnOnelinks: [
      { name: "Hajj Checklist", url: "/", linkType: "internal" },
      {
        name: "Hajj Du’a Book - Bangla",
        url: "https://d2doewlpstuw7k.cloudfront.net/ZamZam-Hajj-Dua-Pocket-Book.pdf",
        target: "blank",
      },
      {
        name: "Hajj Du’a Book - English",
        url: "/#books",
        linkType: "internal",
      },
    ],
    columnTwolinks: [
      { name: "Hajj Videos", url: "#videos", linkType: "internal" },
      { name: "Hajj Map", url: "/", linkType: "internal" },
      {
        name: "Hajj Requirements",
        url: "/#requirements",
        linkType: "internal",
      },
    ],
  },
  {
    headingTitle: "Zamzam",
    columnOnelinks: [
      { name: "About Us", url: "/#aboutUs", linkType: "internal" },
      // {name: "Our Packages", url: "#hajj-packages", linkType: "internal"},
      // {name: "Common Services", url: "/#common-services", linkType: "internal"},
    ],
    columnTwolinks: [
      { name: "10 Years of Service", url: "#gallery", linkType: "internal" },
      // {name: "Registration", url: "https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf", linkType: "external", target:"blank"},
      {
        name: "Registration",
        url: "https://www.facebook.com/ZamzamHajjUSA/",
        linkType: "external",
        target: "blank",
      },
      { name: "Contact Us", url: "/#contact-us", linkType: "internal" },
    ],
  },
  {
    headingTitle: "Connect",
    columnOnelinks: [
      {
        name: "Facebook",
        url: "https://www.facebook.com/ZamZam-Hajj-Kafela-104054951900488/",
        linkType: "external",
        target: "blank",
      },
      {
        name: "YouTube",
        url: "https://www.youtube.com/channel/UCfe06KuigZlc3wtYpXJNRHw",
        linkType: "external",
        target: "blank",
      },
      // {name: "Twitter", url: "/", linkType: "external"},
    ],
    columnTwolinks: [
      {
        name: "WhatsApp",
        url: "https://wa.me/9173486417",
        linkType: "external",
        target: "blank",
      },
      {
        name: "Email Us",
        url: "mailto:yeszamzam@gmail.com",
        linkType: "external",
      },
      { name: "Call Us", url: "tel:+19175407944", linkType: "external" },
    ],
  },
];

class FooterSection extends Component {
  render() {
    const poweredBy = {
      logo: DCLogo,
      link: "/",
    };

    const onLinkClick = (linkInfo) => {
      console.log("linkInfo", linkInfo);
    };

    return (
      <div className="">
        <UmrahServices />
        <FooterWeb
          footerLinks={footerLinks}
          copyRightsText={
            "© 2024 ZamZam Hajj and Umrah Services, Inc. All Rights Reserved"
          }
          termsOfServiceLink={"/terms-condition"}
          privacyPolicyLink={"/privacy-policy"}
          poweredBy={poweredBy}
          onLinkClick={onLinkClick}
        />
      </div>
    );
  }
}

export default FooterSection;
